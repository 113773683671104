import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { datadogRum } from '@datadog/browser-rum';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MarkdownModule } from 'ngx-markdown';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BootstrapErrorComponent } from './bootstrap-error.component';
import { AppHeaderComponent } from './core/app-header/app-header.component';
import {
  API_URL,
  APP_TITLE,
  DEFAULT_MAP_BOUNDS,
  PARTNER_NAME,
  THEME_NAME,
} from './core/config/app.config';
import { MAP_CONFIG } from './core/config/map.config';

@NgModule({
  declarations: [AppComponent, AppHeaderComponent, BootstrapErrorComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FontAwesomeModule,
    MarkdownModule.forRoot(),
    NgbCollapseModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: APP_TITLE, useValue: environment.appTitle },
    { provide: DEFAULT_MAP_BOUNDS, useValue: environment.defaultMapBounds },
    { provide: MAP_CONFIG, useValue: environment.map },
    { provide: PARTNER_NAME, useValue: environment.partnerName },
    { provide: THEME_NAME, useValue: environment.themeName },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule implements DoBootstrap {
  public async ngDoBootstrap(appRef: ApplicationRef) {
    try {
      this.initializeDatadog();
      appRef.bootstrap(AppComponent);
    } catch (error) {
      console.error(error);
      appRef.bootstrap(BootstrapErrorComponent);
    }
  }

  private initializeDatadog() {
    if (environment.datadog.applicationId !== '' && environment.datadog.clientToken !== '') {
      datadogRum.init({
        applicationId: environment.datadog.applicationId,
        clientToken: environment.datadog.clientToken,
        site: environment.datadog.site,
        sessionSampleRate: 100,
      });
    } else if (environment.production) {
      console.warn('No datadog RUM logging configured');
    }
  }
}
