import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { KpiId } from './core/kpi-info/kpi-id';

const routes: Routes = [
  {
    path: 'overview',
    loadChildren: () =>
      import('./kpi-overview/kpi-overview.module').then((m) => m.KpiOverviewModule),
  },
  {
    path: 'traffic-safety/traffic-incidents',
    loadChildren: () =>
      import('./kpi-details/traffic-safety/kpi-incidents/kpi-incidents.module').then(
        (m) => m.KpiIncidentsModule,
      ),
  },
  {
    path: 'traffic-safety/traffic-deaths',
    loadChildren: () =>
      import('./kpi-details/traffic-safety/kpi-deaths/kpi-deaths.module').then(
        (m) => m.KpiDeathsModule,
      ),
  },
  {
    path: 'traffic-safety/traffic-wounded',
    loadChildren: () =>
      import('./kpi-details/traffic-safety/kpi-wounded/kpi-wounded.module').then(
        (m) => m.KpiWoundedModule,
      ),
  },
  {
    path: 'provincial-roads/accessibility-indicator-car',
    data: { kpiId: KpiId.Car },
    loadChildren: () =>
      import('./kpi-details/kpi-accessibility/kpi-accessibility.module').then(
        (m) => m.KpiAccessibilityModule,
      ),
  },
  {
    path: 'provincial-roads/road-maintenance-condition',
    loadChildren: () =>
      import(
        './kpi-details/provincial-roads/kpi-road-maintenance-condition/kpi-road-maintenance-condition.module'
      ).then((m) => m.KpiRoadMaintenanceConditionModule),
  },
  {
    path: 'provincial-roads/road-object-maintenance-condition',
    loadChildren: () =>
      import(
        './kpi-details/provincial-roads/kpi-road-object-maintenance-condition/kpi-road-object-maintenance-condition.module'
      ).then((m) => m.KpiRoadObjectMaintenanceConditionModule),
  },
  {
    path: 'provincial-roads/average-travel-time',
    loadChildren: () =>
      import(
        './kpi-details/provincial-roads/kpi-average-travel-time/kpi-average-travel-time.module'
      ).then((m) => m.KpiAverageTravelTimeModule),
  },
  {
    path: 'provincial-roads/reliable-travel-time',
    loadChildren: () =>
      import(
        './kpi-details/provincial-roads/kpi-reliable-travel-time/kpi-reliable-travel-time.module'
      ).then((m) => m.KpiReliableTravelTimeModule),
  },
  {
    path: 'provincial-roads/road-availability',
    loadChildren: () =>
      import(
        './kpi-details/provincial-roads/kpi-road-availability/kpi-road-availability.module'
      ).then((m) => m.KpiRoadAvailabilityModule),
  },
  {
    path: 'provincial-roads/vehicle-loss-hours',
    loadChildren: () =>
      import(
        './kpi-details/provincial-roads/kpi-vehicle-loss-hours/kpi-vehicle-loss-hours.module'
      ).then((m) => m.KpiVehicleLossHoursModule),
  },
  {
    path: 'bicycle/accessibility-indicator-bicycle',
    data: { kpiId: KpiId.Bicycle },
    loadChildren: () =>
      import('./kpi-details/kpi-accessibility/kpi-accessibility.module').then(
        (m) => m.KpiAccessibilityModule,
      ),
  },
  {
    path: 'bicycle/bicycle-commute',
    loadChildren: () =>
      import('./kpi-details/bicycle/kpi-commute/kpi-commute.module').then(
        (m) => m.KpiCommuteModule,
      ),
  },
  {
    path: 'bicycle/bicycle-directness',
    loadChildren: () =>
      import('./kpi-details/bicycle/kpi-directness/kpi-directness.module').then(
        (m) => m.KpiDirectnessModule,
      ),
  },
  {
    path: 'bicycle/bicycle-smooth-throughput',
    loadChildren: () =>
      import('./kpi-details/bicycle/kpi-smooth-throughput/kpi-smooth-throughput.module').then(
        (m) => m.KpiSmoothThroughputModule,
      ),
  },
  {
    path: 'bicycle/bicycle-route-quality',
    loadChildren: () =>
      import('./kpi-details/bicycle/kpi-route-quality/kpi-route-quality.module').then(
        (m) => m.KpiRouteQualityModule,
      ),
  },
  {
    path: 'public-transport/cost-coverage',
    loadChildren: () =>
      import('./kpi-details/public-transport/kpi-cost-coverage/kpi-cost-coverage.module').then(
        (m) => m.KpiCostCoverageModule,
      ),
  },
  {
    path: 'public-transport/robust-travel-deviation',
    loadChildren: () =>
      import(
        './kpi-details/public-transport/kpi-robust-travel-deviation/kpi-robust-travel-deviation.module'
      ).then((m) => m.KpiRobustTravelDeviationModule),
  },
  {
    path: 'public-transport/traveller-rating',
    loadChildren: () =>
      import(
        './kpi-details/public-transport/kpi-traveller-rating/kpi-traveller-rating.module'
      ).then((m) => m.KpiTravellerRatingModule),
  },
  {
    path: 'public-transport/accessibility-indicator-public-transport',
    data: { kpiId: KpiId.PublicTransport },
    loadChildren: () =>
      import('./kpi-details/kpi-accessibility/kpi-accessibility.module').then(
        (m) => m.KpiAccessibilityModule,
      ),
  },
  {
    path: 'junctions/junction-availability',
    loadChildren: () =>
      import(
        './kpi-details/junction/kpi-junction-availability/kpi-junction-availability.module'
      ).then((m) => m.KpiJunctionAvailabilityModule),
  },
  {
    path: 'junctions/junction-quality',
    loadChildren: () =>
      import('./kpi-details/junction/kpi-junction-quality/kpi-junction-quality.module').then(
        (m) => m.KpiJunctionQualityModule,
      ),
  },
  {
    path: 'junctions/junction-occupancy',
    loadChildren: () =>
      import('./kpi-details/junction/kpi-junction-occupancy/kpi-junction-occupancy.module').then(
        (m) => m.KpiJunctionOccupancyModule,
      ),
  },
  {
    path: 'environment-quality/air-quality',
    loadChildren: () =>
      import('./kpi-details/environment-quality/kpi-air-quality/kpi-air-quality.module').then(
        (m) => m.KpiAirQualityModule,
      ),
  },
  {
    path: 'environment-quality/noise-exposure',
    loadChildren: () =>
      import('./kpi-details/environment-quality/kpi-noise-exposure/kpi-noise-exposure.module').then(
        (m) => m.KpiNoiseExposureModule,
      ),
  },
  {
    path: 'environment-quality/traffic-and-transport-emissions',
    loadChildren: () =>
      import(
        './kpi-details/environment-quality/kpi-traffic-transport-emissions/kpi-traffic-transport-emissions.module'
      ).then((m) => m.KpiTrafficTransportEmissionsModule),
  },
  {
    path: 'environment-quality/zero-emission-public-transport',
    loadChildren: () =>
      import(
        './kpi-details/environment-quality/kpi-zero-emission-public-transport/kpi-zero-emission.module'
      ).then((m) => m.KpiZeroEmissionModule),
  },
  {
    path: 'goods-transport/capacity-transshipment',
    loadChildren: () =>
      import(
        './kpi-details/goods-transport/kpi-capacity-transshipment/kpi-capacity-transshipment.module'
      ).then((m) => m.KpiCapacityTransshipmentModule),
  },
  {
    path: 'goods-transport/accessibility-indicator-goods-transport',
    data: { kpiId: KpiId.GoodsTransport },
    loadChildren: () =>
      import('./kpi-details/kpi-accessibility/kpi-accessibility.module').then(
        (m) => m.KpiAccessibilityModule,
      ),
  },
  {
    path: 'smart-mobility/smart-mobility-peak-off-peak',
    loadChildren: () =>
      import('./kpi-details/smart-mobility/kpi-peak-off-peak/kpi-peak-off-peak.module').then(
        (m) => m.KpiPeakOffPeakModule,
      ),
  },
  {
    path: 'smart-mobility/smart-mobility-intensity-capacity',
    loadChildren: () =>
      import(
        './kpi-details/smart-mobility/kpi-intensity-capacity/kpi-intensity-capacity.module'
      ).then((m) => m.KpiIntensityCapacityModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full',
  },
];

export const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'top',
  scrollOffset: [0, 140],
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
