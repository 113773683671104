import { Component, Inject } from '@angular/core';
import { APP_TITLE, THEME_NAME } from './core/config/app.config';

@Component({
  selector: 'kpi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    @Inject(THEME_NAME) public readonly themeName: string,
    @Inject(APP_TITLE) public readonly appTitle: string,
  ) {}
}
