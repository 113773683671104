<div class="d-flex flex-column min-vh-100">
  <div class="d-flex flex-row flex-grow-0 justify-content-center">
    <kpi-app-header [themeName]="themeName" [appTitle]="appTitle"></kpi-app-header>
  </div>
  <div class="d-flex flex-row flex-grow-1 justify-content-center highcharts-light">
    <router-outlet></router-outlet>
  </div>
  <div class="d-flex flex-row flex-grow-0 justify-content-center">
    <footer class="d-flex flex-column p-2 pt-0">
      <small class="text-body-secondary ms-auto me-auto">Een product van</small>
      <div class="footer-logo ms-auto me-auto">
        <a href="https://www.ndw.nu" target="_blank">
          <img src="/assets/logo-ndw.svg" width="247" height="77" alt="NDW" />
        </a>
      </div>
      <small class="text-body-secondary ms-auto me-auto">
        <a href="https://ndw.nu/privacy-statement" target="_blank">Privacystatement</a>
      </small>
    </footer>
  </div>
</div>
