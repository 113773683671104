<nav class="navbar navbar-expand-lg w-100" [ngClass]="isFixed() ? 'fixed-top' : ''">
  <div class="d-flex flex-column w-100 container-fluid">
    <div class="d-flex flex-row me-0 w-100">
      <div class="navbar-brand d-flex flex-column">
        <a routerLink="">
          <img
            src="/assets/themes/{{ themeName }}/images/logo.svg"
            class="embed-responsive"
            alt="Logo"
          />
        </a>
      </div>
      <div class="d-flex flex-column-reverse w-100 justify-content-between">
        <div *ngIf="isLoggedIn" class="d-flex flex-row">
          <div class="ms-auto">
            <button *ngIf="isLoggedIn()" class="btn btn-sm d-flex" type="button" (click)="logout()">
              <fa-icon [icon]="faSignOut"></fa-icon>&nbsp;Uitloggen
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center me-0 w-100">
      <div class="d-flex flex-row">
        <h5 class="nav-header m-0 p-0 text-nowrap">{{ appTitle }}</h5>
      </div>

      <button
        class="btn btn-primary ms-auto"
        type="button"
        (click)="openDashboardInformationDialog()"
      >
        <fa-icon [icon]="faInfoCircle"></fa-icon>&nbsp;Informatie
      </button>
    </div>

    <div *ngIf="isFixed()" class="w-100">
      <button
        class="navbar-toggler"
        type="button"
        title="navigatie menu"
        (click)="isMenuCollapsed = !isMenuCollapsed"
      >
        &#9776;
      </button>
      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <ng-container *ngFor="let kpiCategory of kpiCategories">
            <li class="nav-item">
              <a
                class="nav-link ps-1 pe-lg-2 pe-xl-3 r-2 pb-0"
                [routerLink]="'/overview'"
                [fragment]="kpiCategory.id"
                (click)="isMenuCollapsed = true"
              >
                {{ kpiCategory.shortName ? kpiCategory.shortName : kpiCategory.name }}
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</nav>
